"use client";
import { useRouter } from "next/navigation";
import { useEffect } from "react";
import { getObjectFromLocalStorage } from "./utils/LocalStorageServices";

const Home = () => {
  const isAuthenticated = getObjectFromLocalStorage("IsAuthenticated");
  const router = useRouter();

  useEffect(() => {
    if (isAuthenticated) {
      router.push("/GYB-Admin-Dashboard");
    } else {
      router.push("/Login");
    }
  }, []);

  return (
    <></>
  );
}

export default Home
