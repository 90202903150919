// localStorageUtils.ts
'use-client'
// Function to set an object in local storage
export function setObjectInLocalStorage(key: string, value: any): void {
    const serializedValue = JSON.stringify(value);
    localStorage.setItem(key, serializedValue);
  }
  
  export function getObjectFromLocalStorage(key: string): any {
    if (typeof window !== 'undefined') {
      const serializedValue = window.localStorage.getItem(key);
      if (serializedValue){
        return JSON.parse(serializedValue);
      }      
    }
    return null;
  }